<template>
  <div class="card">
    <div class="card-header p-1">
      <h4 class="mb-0 ms-2 text-primary text-center">Custo Variável</h4>
      <p class="opacity-50 text-center">
        {{
          disableEdit ? 'Preenchimento Finalizado' : 'Aguardando Preenchimento'
        }}
      </p>
    </div>
    <ul class="nav nav-tabs nav-tabs-highlight nav-justified" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          href="#tab-form-variable-cost"
          class="nav-link active"
          data-bs-toggle="tab"
          aria-selected="true"
          role="tab"
        >
          <div class="d-flex align-items-center">
            <i
              class="ph-user-plus ph-lg bg-primary text-white rounded p-2 me-3"
            ></i>
            <div>
              <div class="fw-semibold">Formulário</div>
              <span class="opacity-50">Cadastro de custo fixo</span>
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          href="#tab-registers-variable-cost"
          class="nav-link"
          data-bs-toggle="tab"
          role="tab"
        >
          <div class="d-flex">
            <i
              class="ph-sort-ascending ph-lg bg-primary text-white rounded p-2 me-3"
            ></i>
            <div>
              <div class="fw-semibold">Dados Inseridos</div>
              <span class="opacity-50">Visualizar e remover dados</span>
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          href="#tab-report-variable-cost"
          class="nav-link"
          ref="tabReportVariableCost"
          :disabled="registers.length === 0"
          data-bs-toggle="tab"
          role="tab"
        >
          <div class="d-flex">
            <i
              class="ph-calculator ph-lg bg-primary text-white rounded p-2 me-3"
            ></i>
            <div>
              <div class="fw-semibold">Relatório</div>
              <span class="opacity-50">Compilado dos dados informados</span>
            </div>
          </div>
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane active fade show"
        id="tab-form-variable-cost"
        role="tabpanel"
      >
        <div class="card-body">
          <form @submit.prevent="saveForm">
            <div>
              <UploadExcelFile
                v-if="showModalUploadExcel"
                @closeModal="closeModal"
                @importData="importData"
              ></UploadExcelFile>
              <DownloadExcelFile
                v-if="showDownloadExcelFile"
                @finish="showDownloadExcelFile = false"
                :fieldsMap="fieldsMap"
                :link="form.LINK"
                :name="'modelo_preenchimento_compras'"
              />
              <ModalViewDraft
                v-if="showModalViewDraft"
                @closeModal="closeModal"
              />
              <UploadAttachment
                v-if="showModalUploadAttachment"
                :companyCode="companyCode"
                :form="form"
                @closeModal="closeModal"
              ></UploadAttachment>
              <ModalAttachment
                v-if="showModalAttachments"
                :companyCode="companyCode"
                :formStepId="form.IDFORMETAPA"
                @closeModal="closeModal"
              ></ModalAttachment>
            </div>
            <div class="row" v-if="disableEdit">
              <div class="col-12">
                <p class="text-center m-5">Formulário finalizado!</p>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12 col-md-4 mb-2">
                <label class="form-label">Custo Variáveis:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid': v$.formItem.item.$errors.length,
                  }"
                  v-model="v$.formItem.item.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option
                    v-for="variableCost in variableCosts"
                    :key="`${variableCost.CODCCUSTO}/${variableCost.CODNATUREZA}`"
                    :value="variableCost"
                  >
                    {{ variableCost.CODCCUSTO }} -
                    {{ variableCost.CENTRODECUSTO }} /
                    {{ variableCost.CODNATUREZA }} -
                    {{ variableCost.NATUREZA }}
                  </option>
                </select>

                <span
                  v-if="v$.formItem.item.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-2 mb-2">
                <label class="form-label">Competência:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid': v$.formItem.competencia.$errors.length,
                  }"
                  v-model="v$.formItem.competencia.$model"
                >
                  <option
                    v-for="month in months"
                    :key="month.value"
                    :value="month.value"
                    :disabled="month.disabled"
                  >
                    {{ month.name }}
                  </option>
                </select>
                <span
                  v-if="v$.formItem.competencia.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-2 mb-2">
                <label class="form-label">Quantidade:</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  placeholder="Quantidade"
                  :class="{
                    'is-invalid': v$.formItem.quantidade.$errors.length,
                  }"
                  v-model="v$.formItem.quantidade.$model"
                />
                <span
                  v-if="v$.formItem.quantidade.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-2 mb-2">
                <label class="form-label">Valor:</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  placeholder="Preço Médio"
                  :class="{
                    'is-invalid': v$.formItem.valor.$errors.length,
                  }"
                  v-model="v$.formItem.valor.$model"
                />
                <span
                  v-if="v$.formItem.valor.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-2 mb-2">
                <label class="form-label">Valor Total:</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  disabled
                  placeholder="Valor Total"
                  :class="{
                    'is-invalid': v$.formItem.total.$errors.length,
                  }"
                  v-model="v$.formItem.total.$model"
                />
                <span
                  v-if="v$.formItem.total.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 mb-1" v-if="!disableEdit">
                <div class="d-flex justify-content-between">
                  <div>
                    <button
                      type="button"
                      class="btn btn-yellow me-2"
                      @click="showModalViewDraft = true"
                    >
                      <i class="ph-calculator me-2"></i> Draft
                    </button>
                    <div class="btn-group me-2" v-if="!disableEdit">
                      <button type="button" class="btn btn-success" disabled>
                        <i class="ph ph-file-xls me-2"></i>Planilha
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        title="Download do modelo de planilha para importação"
                        @click="showDownloadExcelFile = true"
                      >
                        <i class="ph-download"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        title="Realiza o upload da planilha preenchida"
                        @click="showModalUploadExcel = true"
                      >
                        <i class="ph-upload"></i>
                      </button>
                    </div>
                    <div class="btn-group me-2">
                      <button type="button" class="btn btn-secondary" disabled>
                        <i class="ph ph-paperclip-horizontal me-2"></i>Anexos
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary"
                        title="Anexar um novo arquivo"
                        @click="showModalUploadAttachment = true"
                      >
                        <i class="ph-paperclip"></i>
                      </button>
                      <button
                        v-if="!disableEdit"
                        type="button"
                        class="btn btn-secondary"
                        title="Consultar anexos"
                        @click="showModalAttachments = true"
                      >
                        <i class="ph-eye"></i>
                      </button>
                    </div>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-primary ms-2"
                    :disabled="v$.$invalid || disableButton"
                  >
                    <i class="ph-floppy-disk me-2"></i> Cadastrar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="tab-registers-variable-cost"
        role="tabpanel"
      >
        <LoadingData
          v-if="isLoadingRegisters"
          :message="'Carregando lista de registro...'"
        />
        <div v-else>
          <RegisterVariableCostManager
            :registers="registers"
            :form="form"
            @updateForms="finishProcess"
          />
        </div>
      </div>
      <div class="tab-pane fade" id="tab-report-variable-cost" role="tabpanel">
        <LoadingData
          v-if="isLoadingRegisters"
          :message="'Carregando clientes...'"
        />
        <div v-else>
          <ReportVariableCostManager
            :registers="registers"
            :companyCode="companyCode"
            :form="form"
            :stepId="stepId"
            @updateForms="finishProcess"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'

import LoadingData from '@/components/commons/globals/LoadingData.vue'
import RegisterVariableCostManager from '@/components/forms/variableCostManager/RegisterVariableCostManager.vue'
import ReportVariableCostManager from '@/components/forms/variableCostManager/ReportVariableCostManager.vue'
import UploadExcelFile from '@/components/forms/UploadExcelFile.vue'
import DownloadExcelFile from '@/components/forms/DownloadExcelFile.vue'
import ModalViewDraft from '@/components/forms/draft/ModalViewDraft.vue'
import UploadAttachment from '@/components/forms/UploadAttachment.vue'
import ModalAttachment from '@/components/forms/ModalAttachment.vue'
import { validateHeader, atributeValues } from '@/utils/upload'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'FormVariableCostManager',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    RegisterVariableCostManager,
    ReportVariableCostManager,
    UploadExcelFile,
    DownloadExcelFile,
    LoadingData,
    ModalViewDraft,
    UploadAttachment,
    ModalAttachment,
  },
  data() {
    return {
      pageTitle: 'Custos Variáveis',
      formatToCurrencyBr,
      // Modais
      showModalUploadExcel: false,
      showDownloadExcelFile: false,
      showModalViewDraft: false,
      showModalUploadAttachment: false,
      showModalAttachments: false,
      // Loading
      isLoadingCostCenter: true,
      isLoadingRegisters: true,
      disableButton: false,
      fieldsMap: {
        centroCustoCodigo: 'Código Centro de Custo',
        centroCustoNome: 'Centro de Custo',
        naturezaCodigo: 'Código Natureza',
        naturezaNome: 'Natureza',
        competencia: 'Competência',
        quantidade: 'Quantidade',
        valor: 'Valor',
        total: 'Total',
      },
      formItem: {
        item: null,
        centroCustoCodigo: '',
        centroCustoNome: '',
        naturezaCodigo: '',
        naturezaNome: '',
        competencia: '',
        quantidade: '',
        valor: '',
        total: '',
      },
      formItems: [],
      registers: [],
      variableCosts: [],
      months: [
        { value: 1, name: 'Janeiro', disabled: true },
        { value: 2, name: 'Fevereiro', disabled: true },
        { value: 3, name: 'Março', disabled: true },
        { value: 4, name: 'Abril', disabled: true },
        { value: 5, name: 'Maio', disabled: true },
        { value: 6, name: 'Junho', disabled: true },
        { value: 7, name: 'Julho', disabled: true },
        { value: 8, name: 'Agosto', disabled: true },
        { value: 9, name: 'Setembro', disabled: true },
        { value: 10, name: 'Outubro', disabled: true },
        { value: 11, name: 'Novembro', disabled: true },
        { value: 12, name: 'Dezembro', disabled: true },
      ],
    }
  },
  watch: {
    'formItem.item': {
      handler() {
        if (this.formItem.item) {
          this.formItem.centroCustoCodigo = this.formItem.item.CODCCUSTO
          this.formItem.centroCustoNome = this.formItem.item.CENTRODECUSTO
          this.formItem.naturezaCodigo = this.formItem.item.CODNATUREZA
          this.formItem.naturezaNome = this.formItem.item.NATUREZA
          this.formItem.competencia = ''
          console.log(this.formItem.item.MESES)
          const monthsAvaliables = this.formItem.item.MESES.map((m) => m.MES)
          this.months = this.months.map((m) => {
            if (monthsAvaliables.includes(m.value)) {
              // eslint-disable-next-line no-param-reassign
              m.disabled = false
            }
            return m
          })
        }
      },
      deep: true,
    },
    'formItem.competencia': {
      handler() {
        if (this.formItem.competencia) {
          if (this.formItem.item.MESES.length === 0) {
            this.$root.$refs.notification.error(
              'Não foram encontrados valores para a competência selecionada.',
            )
            return false
          }
          const month = this.formItem.item.MESES.find(
            (m) => m.MES === Number(this.formItem.competencia),
          )
          if (!month) {
            this.$root.$refs.notification.error(
              'Não foram encontrados valores para a competência selecionada.',
            )
            return false
          }
          this.formItem.valor = month.CUSTOUNITARIO
        }
      },
      deep: true,
    },
    'formItem.quantidade': {
      handler() {
        if (this.formItem.quantidade && this.formItem.valor) {
          this.formItem.total =
            Number(this.formItem.valor) * Number(this.formItem.quantidade)
        }
      },
      deep: true,
    },
  },
  emits: ['updateForms'],
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },
  },
  methods: {
    finishProcess() {
      this.$emit('updateForms')
    },
    importData(data) {
      const res = validateHeader(data, this.fieldsMap)
      if (!res.status) {
        this.$root.$refs.notification.error(res.message)
        return false
      }
      const values = atributeValues(data, this.fieldsMap)
      if (!values.status) {
        this.$root.$refs.notification.error(values.message)
        return false
      }
      this.formItems = values.data
      this.save()
      this.$refs.tabReportVariableCost.click()
      this.showModalUploadExcel = false
    },
    closeModal() {
      this.showModalUploadExcel = false
      this.showDownloadExcelFile = false
      this.showModalViewDraft = false
      this.showModalUploadAttachment = false
      this.showModalAttachments = false
    },
    downloadTemplateDraftXls() {
      window.open(this.linkTemplateDraftXls, '_blank')
    },
    async getVariableCostsByUnit() {
      try {
        this.isLoadingCostCenter = true
        this.variableCosts = await this.$store.dispatch(
          'getVariableCostsByUnit',
          this.companyCode,
        )
        this.isLoadingCostCenter = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getVariableCostsRegisteredsByUnit() {
      try {
        this.isLoadingRegisters = true
        this.registers = await this.$store.dispatch(
          'getVariableCostsRegisteredsByUnit',
          {
            companyCode: this.companyCode,
            stepId: this.stepId,
            formId: this.form.IDFORMULARIO,
            formStepId: this.form.IDFORMETAPA,
          },
        )
        this.isLoadingRegisters = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async saveForm() {
      if (!this.v$.$invalid) {
        this.formItem.centroCustoCodigo = this.formItem.item.CODCCUSTO
        this.formItem.centroCustoNome = this.formItem.item.CENTRODECUSTO
        this.formItem.naturezaCodigo = this.formItem.item.CODNATUREZA
        this.formItem.naturezaNome = this.formItem.item.NATUREZA
        this.formItems = [this.formItem]
        this.save()
      }
    },
    async save() {
      try {
        this.disableButton = true
        const data = await this.$store.dispatch('saveVariableCostManager', {
          form: {
            stepId: this.stepId,
            formId: this.form.IDFORMULARIO,
            formStepId: this.form.IDFORMETAPA,
            formItems: this.formItems,
          },
          companyCode: this.companyCode,
        })
        this.$root.$refs.notification.success(data.message)
        this.formItem = {
          item: null,
          centroCustoCodigo: '',
          centroCustoNome: '',
          naturezaCodigo: '',
          naturezaNome: '',
          competencia: '',
          quantidade: '',
          valor: '',
          total: '',
        }
        this.getVariableCostsRegisteredsByUnit()
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  validations() {
    return {
      formItem: {
        competencia: {
          required,
        },
        item: {
          required,
        },
        quantidade: {
          required,
          minValue: minValue(1),
        },
        valor: {
          required,
          minValue: minValue(1),
        },
        total: {
          required,
          minValue: minValue(1),
        },
      },
    }
  },
  mounted() {
    this.getVariableCostsRegisteredsByUnit()
    this.getVariableCostsByUnit()
  },
}
</script>
