<template>
  <div class="card">
    <div class="card-header p-1">
      <h4 class="mb-0 ms-2 text-primary text-center">Comercial</h4>
      <p class="opacity-50 text-center">
        {{
          disableEdit ? 'Preenchimento Finalizado' : 'Aguardando Preenchimento'
        }}
      </p>
    </div>
    <ul class="nav nav-tabs nav-tabs-highlight nav-justified" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          href="#tab-form-commercial"
          class="nav-link active"
          data-bs-toggle="tab"
          aria-selected="true"
          role="tab"
        >
          <div class="d-flex align-items-center">
            <i
              class="ph-user-plus ph-lg bg-primary text-white rounded p-2 me-3"
            ></i>
            <div>
              <div class="fw-semibold">Formulário</div>
              <span class="opacity-50">Cadastre orçamento comercial</span>
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          href="#tab-registers-commercial"
          class="nav-link"
          data-bs-toggle="tab"
          role="tab"
        >
          <div class="d-flex">
            <i
              class="ph-sort-ascending ph-lg bg-primary text-white rounded p-2 me-3"
            ></i>
            <div>
              <div class="fw-semibold">Dados Inseridos</div>
              <span class="opacity-50">Editar dados do comercial</span>
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          ref="tabReportCommercial"
          href="#tab-report-commercial"
          class="nav-link"
          data-bs-toggle="tab"
          role="tab"
        >
          <div class="d-flex">
            <i
              class="ph-calculator ph-lg bg-primary text-white rounded p-2 me-3"
            ></i>
            <div>
              <div class="fw-semibold">Relatório</div>
              <span class="opacity-50">Compilado dos dados informados</span>
            </div>
          </div>
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div
        id="avaliation"
        v-if="avaliation !== null && avaliation.STATUSCOMERCIAL == -1"
      >
        <div
          class="alert bg-warning text-white alert-dismissible fade show py-1 mb-0"
        >
          <h6>Reprovado:</h6>
          <span class="fw-semibold">Motivo:</span>
          {{ avaliation.JUSTIFICATIVACOMERCIAL }}
        </div>
      </div>
      <div
        class="tab-pane active fade show"
        id="tab-form-commercial"
        role="tabpanel"
      >
        <div class="card-body">
          <form @submit.prevent="saveCommercialForm">
            <div>
              <UploadExcelFile
                v-if="showModalUploadExcel"
                @closeModal="closeModal"
                @importData="importData"
              ></UploadExcelFile>
              <DownloadExcelFile
                v-if="showDownloadExcelFile"
                :link="form.LINK"
                @finish="showDownloadExcelFile = false"
                :fieldsMap="fieldsMap"
                :name="'modelo_preenchimento_comercial'"
              />
              <ModalViewDraft
                v-if="showModalViewDraft"
                @closeModal="closeModal"
              />
              <UploadAttachment
                v-if="showModalUploadAttachment"
                :companyCode="companyCode"
                :form="form"
                @closeModal="closeModal"
              ></UploadAttachment>
              <ModalAttachment
                v-if="showModalAttachments"
                :companyCode="companyCode"
                :formStepId="form.IDFORMETAPA"
                @closeModal="closeModal"
              ></ModalAttachment>
            </div>
            <div class="row">
              <div class="col-12 col-md-8 mb-3">
                <label class="form-label">Cliente:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid': v$.formClient.cliente.$errors.length,
                  }"
                  v-model="v$.formClient.cliente.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option value="NOVO CLIENTE">*NOVO CLIENTE*</option>
                  <option
                    v-for="client in clients"
                    :key="client.name"
                    :value="client.name"
                  >
                    {{ client.name }}
                  </option>
                </select>

                <span
                  v-if="v$.formClient.cliente.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label">Competência:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid': v$.formClient.competencia.$errors.length,
                  }"
                  v-model="v$.formClient.competencia.$model"
                >
                  <option
                    v-for="month in months"
                    :key="month.value"
                    :value="month.value"
                  >
                    {{ month.name }}
                  </option>
                </select>
                <span
                  v-if="v$.formClient.competencia.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>

              <div class="col-12 col-md-4 mb-3">
                <label class="form-label">Volume:</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  placeholder="Volume de vendas"
                  :class="{
                    'is-invalid': v$.formClient.volume.$errors.length,
                  }"
                  v-model="v$.formClient.volume.$model"
                  @keyup="calculateTotalValue('volume')"
                />
                <span
                  v-if="v$.formClient.volume.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label">Preço Médio:</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  placeholder="Volume de vendas"
                  :class="{
                    'is-invalid': v$.formClient.precoMedio.$errors.length,
                  }"
                  v-model="v$.formClient.precoMedio.$model"
                  @keyup="calculateTotalValue('precoMedio')"
                />
                <span
                  v-if="v$.formClient.precoMedio.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label">Valor Total:</label>
                <input
                  type="number"
                  readonly
                  disabled
                  class="form-control"
                  placeholder="Valor total"
                  v-model="formClient.valorTotal"
                />
              </div>
              <div class="col-12 mb-1" v-if="!disableEdit">
                <div class="d-flex justify-content-between">
                  <div>
                    <button
                      type="button"
                      class="btn btn-yellow me-2"
                      @click="showModalViewDraft = true"
                    >
                      <i class="ph-calculator me-2"></i> Draft
                    </button>
                    <div class="btn-group me-2" v-if="!disableEdit">
                      <button type="button" class="btn btn-success" disabled>
                        <i class="ph ph-file-xls me-2"></i>Planilha
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        title="Download do modelo de planilha para importação"
                        @click="showDownloadExcelFile = true"
                      >
                        <i class="ph-download"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        title="Realiza o upload da planilha preenchida"
                        @click="showModalUploadExcel = true"
                      >
                        <i class="ph-upload"></i>
                      </button>
                    </div>
                    <div class="btn-group me-2">
                      <button type="button" class="btn btn-secondary" disabled>
                        <i class="ph ph-paperclip-horizontal me-2"></i>Anexos
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary"
                        title="Anexar um novo arquivo"
                        @click="showModalUploadAttachment = true"
                      >
                        <i class="ph-paperclip"></i>
                      </button>
                      <button
                        v-if="!disableEdit"
                        type="button"
                        class="btn btn-secondary"
                        title="Consultar anexos"
                        @click="showModalAttachments = true"
                      >
                        <i class="ph-eye"></i>
                      </button>
                    </div>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-primary ms-2"
                    :disabled="v$.$invalid || disableButton"
                  >
                    <i class="ph-floppy-disk me-2"></i> Cadastrar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="tab-pane fade" id="tab-registers-commercial" role="tabpanel">
        <LoadingData
          v-if="isLoadingRegisters"
          :message="'Carregando clientes...'"
        />
        <div v-else>
          <RegisterCommercial
            :registers="registers"
            :companyCode="companyCode"
            :form="form"
            :stepId="stepId"
            @updateForms="updateForms"
          />
        </div>
      </div>
      <div class="tab-pane fade" id="tab-report-commercial" role="tabpanel">
        <LoadingData
          v-if="isLoadingRegisters"
          :message="'Carregando clientes...'"
        />
        <div v-else>
          <ReportCommercial
            :commercial="registers"
            :companyCode="companyCode"
            :form="form"
            :stepId="stepId"
            @updateForms="updateForms"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, sameAs } from '@vuelidate/validators'

import LoadingData from '@/components/commons/globals/LoadingData.vue'
import RegisterCommercial from '@/components/forms/commercial/RegisterCommercial.vue'
import ReportCommercial from '@/components/forms/commercial/ReportCommercial.vue'
import UploadExcelFile from '@/components/forms/UploadExcelFile.vue'
import DownloadExcelFile from '@/components/forms/DownloadExcelFile.vue'
import ModalViewDraft from '@/components/forms/draft/ModalViewDraft.vue'
import UploadAttachment from '@/components/forms/UploadAttachment.vue'
import ModalAttachment from '@/components/forms/ModalAttachment.vue'
import { validateHeader, atributeValues } from '@/utils/upload'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'FormCommercial',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    RegisterCommercial,
    ReportCommercial,
    UploadExcelFile,
    DownloadExcelFile,
    LoadingData,
    ModalViewDraft,
    UploadAttachment,
    ModalAttachment,
  },
  data() {
    return {
      formatToCurrencyBr,
      // Modais
      showModalUploadExcel: false,
      showDownloadExcelFile: false,
      showModalViewDraft: false,
      showModalUploadAttachment: false,
      showModalAttachments: false,
      // Loading
      isLoadingClients: true,
      isLoadingRegisters: true,
      isChecked: false,
      disableButton: false,
      fieldsMap: {
        cliente: 'Cliente',
        competencia: 'Competência',
        volume: 'Volume',
        precoMedio: 'Preço Médio',
        valorTotal: 'Valor Total',
      },
      formClient: {
        cliente: '',
        competencia: '',
        volume: '',
        precoMedio: '',
        valorTotal: '',
      },
      formClients: [],
      registers: [],
      clients: [],
      avaliation: null,
      months: [
        { value: '01', name: 'Janeiro' },
        { value: '02', name: 'Fevereiro' },
        { value: '03', name: 'Março' },
        { value: '04', name: 'Abril' },
        { value: '05', name: 'Maio' },
        { value: '06', name: 'Junho' },
        { value: '07', name: 'Julho' },
        { value: '08', name: 'Agosto' },
        { value: '09', name: 'Setembro' },
        { value: '10', name: 'Outubro' },
        { value: '11', name: 'Novembro' },
        { value: '12', name: 'Dezembro' },
      ],
    }
  },
  emits: ['updateForms'],
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },
  },
  methods: {
    updateForms(needReload) {
      this.getCommercialBudget()
      if (needReload) {
        this.$emit('updateForms')
      }
    },
    importData(data) {
      const res = validateHeader(data, this.fieldsMap)
      if (!res.status) {
        this.$root.$refs.notification.error(res.message)
        return false
      }
      const values = atributeValues(data, this.fieldsMap)
      if (!values.status) {
        this.$root.$refs.notification.error(values.message)
        return false
      }
      this.formClients = values.data
      this.save()
      this.$refs.tabReportCommercial.click()
      this.showModalUploadExcel = false
    },
    closeModal() {
      this.showModalUploadExcel = false
      this.showDownloadExcelFile = false
      this.showModalUploadAttachment = false
      this.showModalAttachments = false
      this.showModalViewDraft = false
    },
    calculateTotalValue() {
      const value = this.formClient.volume * this.formClient.precoMedio
      this.formClient.valorTotal = Number(value).toFixed(2)
    },
    downloadTemplateDraftXls() {
      window.open(this.linkTemplateDraftXls, '_blank')
    },
    async getClients() {
      try {
        this.isLoadingClients = true
        this.clients = await this.$store.dispatch(
          'getClientsList',
          this.companyCode,
        )
        this.isLoadingClients = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getCommercialBudget() {
      try {
        this.isLoadingRegisters = true
        this.registers = await this.$store.dispatch(
          'getCommercialBudget',
          this.companyCode,
        )
        this.verifyReprovation()
        this.isLoadingRegisters = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async saveCommercialForm() {
      if (!this.v$.$invalid) {
        this.formClients = [this.formClient]
        this.save()
      }
    },
    async save() {
      try {
        this.closeModal()
        this.disableButton = true
        const data = await this.$store.dispatch('saveCommercialForm', {
          form: {
            stepId: this.stepId,
            formStepId: this.form.IDFORMETAPA,
            formId: this.form.IDFORMULARIO,
            formClients: this.formClients,
          },
          companyCode: this.companyCode,
        })
        this.$root.$refs.notification.success(data.message)
        this.formClient = {
          cliente: '',
          competencia: '',
          volume: '',
          precoMedio: '',
          valorTotal: '',
        }
        this.getCommercialBudget()

        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async verifyReprovation() {
      try {
        this.avaliation = await this.$store.dispatch(
          'getAvaliationControllershipEvaluation',
          {
            companyCode: this.companyCode,
            version: this.registers[0].VERSAO,
            type: 'Commercial',
          },
        )

        this.isLoadingRegisters = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  validations() {
    return {
      formClient: {
        volume: {
          required,
        },
        cliente: {
          required,
        },
        competencia: {
          required,
        },
        precoMedio: {
          required,
        },
      },
    }
  },
  mounted() {
    this.getClients()
    this.getCommercialBudget()
  },
}
</script>
