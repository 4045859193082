<template>
  <div class="card-body">
    <LoadingData v-if="isLoadingParams" />
    <form v-else @submit.prevent="save">
      <div class="row" v-if="disableEdit">
        <div class="col-12">
          <p class="text-center m-5">Formulário finalizado!</p>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12 col-md-6 mb-3">
          <label class="form-label">Departamento:</label>
          <select
            class="form-select"
            placeholder="Selecione uma opção"
            :class="{
              'is-invalid': v$.newCollaborator.section.$errors.length,
            }"
            v-model="v$.newCollaborator.section.$model"
          >
            <option value="" disabled>Selecione uma opção</option>
            <option
              v-for="section in params.sections"
              :key="section.CODIGO"
              :value="section"
            >
              {{ section.CODIGO }} - {{ section.DESCRICAO }}
            </option>
          </select>

          <span
            v-if="v$.newCollaborator.section.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <label class="form-label">Centro de Custo:</label>
          <select
            class="form-select"
            placeholder="Selecione uma opção"
            :class="{
              'is-invalid': v$.newCollaborator.centerCost.$errors.length,
            }"
            v-model="v$.newCollaborator.centerCost.$model"
          >
            <option value="" disabled>Selecione uma opção</option>
            <option
              v-for="CC in params.costCenter"
              :key="CC.CODCCUSTO"
              :value="CC"
            >
              {{ CC.CODCCUSTO }} - {{ CC.CENTRODECUSTO }}
            </option>
          </select>

          <span
            v-if="v$.newCollaborator.centerCost.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <label class="form-label">Cargo:</label>
          <select
            class="form-select"
            placeholder="Selecione uma opção"
            :class="{
              'is-invalid': v$.newCollaborator.position.$errors.length,
            }"
            v-model="v$.newCollaborator.position.$model"
          >
            <option value="" disabled>Selecione uma opção</option>
            <option
              value='{"CODIGO": "NOVO CARGO", "CARGO" : "CARGO NÃO REGISTRADO"}'
            >
              000 - CARGO NÃO REGISTRADO
            </option>
            <option
              v-for="position in params.positions"
              :key="position.CODIGO"
              :value="position"
            >
              {{ position.CODIGO }} - {{ position.CARGO }}
            </option>
          </select>

          <span
            v-if="v$.newCollaborator.position.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <label class="form-label">Competência:</label>
          <select
            class="form-select"
            placeholder="Selecione uma opção"
            :class="{
              'is-invalid': v$.newCollaborator.month.$errors.length,
            }"
            v-model="v$.newCollaborator.month.$model"
          >
            <option
              v-for="month in months"
              :key="month.value"
              :value="month.value"
            >
              {{ month.name }}
            </option>
          </select>
          <span
            v-if="v$.newCollaborator.month.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>

        <div class="col-12 col-md-3 mb-3">
          <label class="form-label">Salário:</label>
          <input
            type="number"
            step="0.01"
            class="form-control"
            placeholder="Salário"
            :class="{
              'is-invalid': v$.newCollaborator.salary.$errors.length,
            }"
            v-model="v$.newCollaborator.salary.$model"
          />
          <span
            v-if="v$.newCollaborator.salary.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <label class="form-label">Periculosidade:</label>
          <select
            class="form-select"
            placeholder="Selecione uma opção"
            :class="{
              'is-invalid': v$.newCollaborator.periculosidade.$errors.length,
            }"
            v-model="v$.newCollaborator.periculosidade.$model"
          >
            <option value="" disabled>Selecione uma opção</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
          </select>
          <span
            v-if="v$.newCollaborator.periculosidade.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <label class="form-label">Adicional Noturno:</label>
          <select
            class="form-select"
            placeholder="Selecione uma opção"
            :class="{
              'is-invalid': v$.newCollaborator.adicionalNoturno.$errors.length,
            }"
            v-model="v$.newCollaborator.adicionalNoturno.$model"
          >
            <option value="" disabled>Selecione uma opção</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
          </select>
          <span
            v-if="v$.newCollaborator.adicionalNoturno.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <label class="form-label">Insalubridade:</label>
          <select
            class="form-select"
            placeholder="Selecione uma opção"
            :class="{
              'is-invalid': v$.newCollaborator.insalubridade.$errors.length,
            }"
            v-model="v$.newCollaborator.insalubridade.$model"
          >
            <option value="" disabled>Selecione uma opção</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
          </select>
          <span
            v-if="v$.newCollaborator.insalubridade.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="col-12 mb-1" v-if="!disableEdit">
          <div class="text-end">
            <button
              type="submit"
              class="btn btn-primary ms-2"
              :disabled="v$.$invalid || disableButton"
            >
              <i class="ph-floppy-disk me-2"></i> Cadastrar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'

import LoadingData from '@/components/commons/globals/LoadingData.vue'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'FormNewCollaborador',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    LoadingData,
  },
  data() {
    return {
      formatToCurrencyBr,
      // Loading
      isLoadingParams: true,
      disableButton: false,
      params: {
        sections: [],
        costCenter: [],
        positions: [],
      },
      newCollaborator: {
        position: '',
        centerCost: '',
        section: '',
        salary: '',
        month: '',
        periculosidade: '',
        adicionalNoturno: '',
        insalubridade: '',
      },

      months: [
        { value: '01', name: 'Janeiro' },
        { value: '02', name: 'Fevereiro' },
        { value: '03', name: 'Março' },
        { value: '04', name: 'Abril' },
        { value: '05', name: 'Maio' },
        { value: '06', name: 'Junho' },
        { value: '07', name: 'Julho' },
        { value: '08', name: 'Agosto' },
        { value: '09', name: 'Setembro' },
        { value: '10', name: 'Outubro' },
        { value: '11', name: 'Novembro' },
        { value: '12', name: 'Dezembro' },
      ],
    }
  },
  emits: ['reloadData'],
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },
  },
  methods: {
    finishProcess() {
      this.$emit('reloadData')
    },
    async getPersonalCostsParams() {
      try {
        this.isLoadingParams = true
        this.params = await this.$store.dispatch(
          'getPersonalCostsParams',
          this.companyCode,
        )
        this.isLoadingParams = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async save() {
      try {
        this.disableButton = true
        const data = await this.$store.dispatch(
          'savePersonalCostsNewCollaborator',
          {
            form: {
              stepId: this.stepId,
              formId: this.form.IDFORMULARIO,
              formStepId: this.form.IDFORMETAPA,
              newCollaborator: this.newCollaborator,
            },
            companyCode: this.companyCode,
          },
        )
        this.$root.$refs.notification.success(data.message)
        this.newCollaborator = {
          position: '',
          centerCost: '',
          section: '',
          salary: '',
          month: '',
          periculosidade: '',
          adicionalNoturno: '',
          insalubridade: '',
        }
        this.$emit('reloadData')
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  validations() {
    return {
      newCollaborator: {
        position: {
          required,
        },
        centerCost: {
          required,
        },
        section: {
          required,
        },
        salary: {
          required,
        },
        month: {
          required,
        },
        periculosidade: {
          required,
        },
        adicionalNoturno: {
          required,
        },
        insalubridade: {
          required,
        },
      },
    }
  },
  mounted() {
    this.getPersonalCostsParams()
  },
}
</script>
