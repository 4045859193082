<template>
  <UploadExcelFile
    v-if="showModalUploadExcel"
    @closeModal="closeModal"
    @importData="importData"
  ></UploadExcelFile>
  <DownloadExcelFile
    v-if="showDownloadExcelFile"
    @finish="showDownloadExcelFile = false"
    :fieldsMap="fieldsMap"
    :link="form.LINK"
    :name="'draft_initial'"
  />
  <UploadAttachment
    v-if="showModalUploadAttachment"
    :companyCode="companyCode"
    :form="form"
    @closeModal="closeModal"
  ></UploadAttachment>
  <ModalAttachment
    v-if="showModalAttachments"
    :companyCode="companyCode"
    :formStepId="form.IDFORMETAPA"
    @closeModal="closeModal"
  ></ModalAttachment>
  <div class="card">
    <div class="card-header p-1">
      <h4 class="mb-0 ms-2 text-primary text-center">Draft Inicial</h4>
      <p class="opacity-50 text-center">
        {{
          disableEdit ? 'Preenchimento Finalizado' : 'Aguardando Preenchimento'
        }}
      </p>
    </div>

    <div class="card-body">
      <form @submit.prevent="saveDraft">
        <div class="row">
          <div class="col-12 col-md-3"></div>
          <div class="col-12 col-md-3">
            <h6 class="text-center mb-1">Ano Anterior</h6>
          </div>
          <div class="col-12 col-md-3">
            <h6 class="text-center mb-1">Variação (%)</h6>
          </div>
          <div class="col-12 col-md-3">
            <h6 class="text-center mb-1">Valores Orçados</h6>
          </div>
        </div>
        <!-- Volume -->
        <div class="row mb-2">
          <label class="col-form-label col-lg-3"
            >Volume <span class="text-danger">*</span></label
          >
          <div class="col-12 col-lg-3">
            <input
              type="text"
              step="0.01"
              readonly
              class="form-control text-center"
              placeholder="Informe o volume de vendas"
              :value="formatToNumber(draftPreviousYear.volume)"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              type="number"
              step="0.01"
              :readonly="disableEdit"
              class="form-control text-center"
              placeholder="Informe a variação volume de vendas"
              v-model="draftVariation.volume"
              @keyup="calculateBudget('volume', 'variation')"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              id="quantity"
              :readonly="disableEdit"
              type="number"
              step="0.01"
              @keyup="calculateBudget('volume', 'budget')"
              class="form-control text-center"
              :class="{
                'is-invalid': v$.draftBudget.volume.$errors.length,
              }"
              v-model="v$.draftBudget.volume.$model"
            />
            <span
              v-if="v$.draftBudget.volume.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
            <span
              v-if="v$.draftBudget.volume.minValue.$invalid"
              class="invalid-feedback"
            >
              Preencha com um valor maior que 0
            </span>
          </div>
        </div>
        <!-- /Volume -->
        <!-- Receita Bruta -->
        <div class="row mb-2">
          <label class="col-form-label col-lg-3"
            >Receita Bruta <span class="text-danger">*</span></label
          >
          <div class="col-12 col-lg-3">
            <input
              type="text"
              step="0.01"
              readonly
              class="form-control text-center"
              placeholder="Informe a receita bruta"
              :value="formatToCurrencyBr(draftPreviousYear.receitaBruta)"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              type="number"
              :readonly="disableEdit"
              step="0.01"
              class="form-control text-center"
              placeholder="Informe a variação da receita bruta"
              v-model="draftVariation.receitaBruta"
              @keyup="calculateBudget('receitaBruta', 'variation')"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              id="quantity"
              :readonly="disableEdit"
              type="number"
              step="0.01"
              @keyup="calculateBudget('receitaBruta', 'budget')"
              class="form-control text-center"
              :class="{
                'is-invalid': v$.draftBudget.receitaBruta.$errors.length,
              }"
              v-model="v$.draftBudget.receitaBruta.$model"
            />
            <span
              v-if="v$.draftBudget.receitaBruta.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
            <span
              v-if="v$.draftBudget.receitaBruta.minValue.$invalid"
              class="invalid-feedback"
            >
              Preencha com um valor maior que 0
            </span>
          </div>
        </div>
        <!-- /Receita Bruta -->
        <!-- Deduções -->
        <div class="row mb-2">
          <label class="col-form-label col-lg-3"
            >Deduções <span class="text-danger">*</span></label
          >
          <div class="col-12 col-lg-3">
            <input
              type="text"
              readonly
              class="form-control text-center"
              placeholder="Informe o valor das deduções"
              :value="formatToCurrencyBr(draftPreviousYear.deducoes)"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              type="number"
              step="0.01"
              :readonly="disableEdit"
              class="form-control text-center"
              placeholder="Informe a variação das deduções"
              v-model="draftVariation.deducoes"
              @keyup="calculateBudget('deducoes', 'variation')"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              id="quantity"
              type="number"
              :readonly="disableEdit"
              step="0.01"
              @keyup="calculateBudget('deducoes', 'budget')"
              class="form-control text-center"
              :class="{
                'is-invalid': v$.draftBudget.deducoes.$errors.length,
              }"
              v-model="v$.draftBudget.deducoes.$model"
            />
            <span
              v-if="v$.draftBudget.deducoes.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
            <span
              v-if="v$.draftBudget.deducoes.minValue.$invalid"
              class="invalid-feedback"
            >
              Preencha com um valor maior que 0
            </span>
          </div>
        </div>
        <!-- /Deduções -->
        <!-- Receita Liquida -->
        <div class="row mb-2">
          <label class="col-form-label col-lg-3"
            >Receita Líquida <span class="text-danger">*</span></label
          >
          <div class="col-12 col-lg-3">
            <input
              type="text"
              readonly
              class="form-control text-center"
              placeholder="Informe a receita liquida"
              :value="formatToCurrencyBr(draftPreviousYear.receitaLiquida)"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              type="number"
              step="0.01"
              readonly
              class="form-control text-center"
              placeholder="Informe a variação da receita liquida"
              v-model="draftVariation.receitaLiquida"
              @keyup="calculateBudget('receitaLiquida', 'variation')"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              id="quantity"
              type="number"
              readonly
              step="0.01"
              @keyup="calculateBudget('receitaLiquida', 'budget')"
              class="form-control text-center"
              :class="{
                'is-invalid': v$.draftBudget.receitaLiquida.$errors.length,
              }"
              v-model="v$.draftBudget.receitaLiquida.$model"
            />
            <span
              v-if="v$.draftBudget.receitaLiquida.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
            <span
              v-if="v$.draftBudget.receitaLiquida.minValue.$invalid"
              class="invalid-feedback"
            >
              Preencha com um valor maior que 0
            </span>
          </div>
        </div>
        <!-- /Receita Liquida -->
        <!-- Custos Variáveis -->
        <div class="row mb-2">
          <label class="col-form-label col-lg-3"
            >Custos Variáveis <span class="text-danger">*</span></label
          >
          <div class="col-12 col-lg-3">
            <input
              type="text"
              readonly
              class="form-control text-center"
              placeholder="Informe o valor dos custos variáveis"
              :value="formatToCurrencyBr(draftPreviousYear.custosVariaveis)"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              type="number"
              :readonly="disableEdit"
              step="0.01"
              class="form-control text-center"
              placeholder="Informe a variação dos custos variáveis"
              v-model="draftVariation.custosVariaveis"
              @keyup="calculateBudget('custosVariaveis', 'variation')"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              id="quantity"
              type="number"
              :readonly="disableEdit"
              step="0.01"
              @keyup="calculateBudget('custosVariaveis', 'budget')"
              class="form-control text-center"
              :class="{
                'is-invalid': v$.draftBudget.custosVariaveis.$errors.length,
              }"
              v-model="v$.draftBudget.custosVariaveis.$model"
            />
            <span
              v-if="v$.draftBudget.custosVariaveis.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
            <span
              v-if="v$.draftBudget.custosVariaveis.minValue.$invalid"
              class="invalid-feedback"
            >
              Preencha com um valor maior que 0
            </span>
          </div>
        </div>
        <!-- /Custos Variáveis -->
        <!-- Margem de Contribuição -->
        <div class="row mb-2">
          <label class="col-form-label col-lg-3"
            >Margem de Contribuição <span class="text-danger">*</span></label
          >
          <div class="col-12 col-lg-3">
            <input
              type="text"
              readonly
              class="form-control text-center"
              placeholder="Informe a margem de contribuição"
              :value="formatToCurrencyBr(draftPreviousYear.margemContribuicao)"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              type="number"
              readonly
              step="0.01"
              class="form-control text-center"
              placeholder="Informe a variação da margem de contribuição"
              v-model="draftVariation.margemContribuicao"
              @keyup="calculateBudget('margemContribuicao', 'variation')"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              id="quantity"
              readonly
              type="number"
              step="0.01"
              @keyup="calculateBudget('margemContribuicao', 'budget')"
              class="form-control text-center"
              :class="{
                'is-invalid': v$.draftBudget.margemContribuicao.$errors.length,
              }"
              v-model="v$.draftBudget.margemContribuicao.$model"
            />
            <span
              v-if="v$.draftBudget.margemContribuicao.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
            <span
              v-if="v$.draftBudget.margemContribuicao.minValue.$invalid"
              class="invalid-feedback"
            >
              Preencha com um valor maior que 0
            </span>
          </div>
        </div>
        <!-- /Margem de Contribuição -->
        <!-- Custos Fixos -->
        <div class="row mb-2">
          <label class="col-form-label col-lg-3"
            >Custos Fixos <span class="text-danger">*</span></label
          >
          <div class="col-12 col-lg-3">
            <input
              type="text"
              readonly
              class="form-control text-center"
              placeholder="Informe o valor dos custos fixos"
              :value="formatToCurrencyBr(draftPreviousYear.custosFixos)"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              type="number"
              step="0.01"
              :readonly="disableEdit"
              class="form-control text-center"
              placeholder="Informe a variação dos custos fixos"
              v-model="draftVariation.custosFixos"
              @keyup="calculateBudget('custosFixos', 'variation')"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              id="quantity"
              type="number"
              :readonly="disableEdit"
              step="0.01"
              @keyup="calculateBudget('custosFixos', 'budget')"
              class="form-control text-center"
              :class="{
                'is-invalid': v$.draftBudget.custosFixos.$errors.length,
              }"
              v-model="v$.draftBudget.custosFixos.$model"
            />
            <span
              v-if="v$.draftBudget.custosFixos.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
            <span
              v-if="v$.draftBudget.custosFixos.minValue.$invalid"
              class="invalid-feedback"
            >
              Preencha com um valor maior que 0
            </span>
          </div>
        </div>
        <!-- /Custos Fixos -->
        <!-- Ebitda -->
        <div class="row mb-2">
          <label class="col-form-label col-lg-3"
            >Ebitda <span class="text-danger">*</span></label
          >
          <div class="col-12 col-lg-3">
            <input
              type="text"
              readonly
              class="form-control text-center"
              placeholder="Informe o valor do Ebitda"
              :value="formatToCurrencyBr(draftPreviousYear.ebitda)"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              type="number"
              step="0.01"
              readonly
              class="form-control text-center"
              placeholder="Informe a variação do Ebitda"
              v-model="draftVariation.ebitda"
              @keyup="calculateBudget('ebitda', 'variation')"
            />
          </div>
          <div class="col-12 col-lg-3">
            <input
              id="quantity"
              type="number"
              step="0.01"
              readonly
              @keyup="calculateBudget('ebitda', 'budget')"
              class="form-control text-center"
              :class="{
                'is-invalid': v$.draftBudget.ebitda.$errors.length,
              }"
              v-model="v$.draftBudget.ebitda.$model"
            />
          </div>
        </div>
        <!-- /Ebitda -->
        <div
          class="row justify-content-between"
          v-if="form.STATUS_FORMULARIO_ETAPA == 0"
        >
          <div class="col">
            <div class="form-check mb-2">
              <input
                type="checkbox"
                class="form-check-input"
                id="cc_ls_c"
                value="1"
                :class="{
                  'is-invalid': v$.isChecked.$errors.length,
                }"
                v-model="v$.isChecked.$model"
              />
              <label class="form-check-label" for="cc_ls_c"
                >Não será possivel editar o formulário após finalizar o
                preenchimento.</label
              >
              <span
                v-if="v$.isChecked.sameAs.$invalid"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <div class="btn-group me-2" v-if="!disableEdit">
                <button type="button" class="btn btn-success" disabled>
                  <i class="ph ph-file-xls me-2"></i>Planilha
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  title="Download do modelo de planilha para importação"
                  @click="showDownloadExcelFile = true"
                >
                  <i class="ph-download"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  title="Realiza o upload da planilha preenchida"
                  @click="showModalUploadExcel = true"
                >
                  <i class="ph-upload"></i>
                </button>
              </div>
              <div class="btn-group me-2">
                <button type="button" class="btn btn-secondary" disabled>
                  <i class="ph ph-paperclip-horizontal me-2"></i>Anexos
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  title="Anexar um novo arquivo"
                  @click="showModalUploadAttachment = true"
                >
                  <i class="ph-paperclip"></i>
                </button>
                <button
                  v-if="!disableEdit"
                  type="button"
                  class="btn btn-secondary"
                  title="Consultar anexos"
                  @click="showModalAttachments = true"
                >
                  <i class="ph-eye"></i>
                </button>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-success ms-2"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>
                <i class="ph-floppy-disk me-2"></i> Finalizar Preenchimento
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, sameAs } from '@vuelidate/validators'
import UploadExcelFile from '@/components/forms/UploadExcelFile.vue'
import DownloadExcelFile from '@/components/forms/DownloadExcelFile.vue'
import UploadAttachment from '@/components/forms/UploadAttachment.vue'
import ModalAttachment from '@/components/forms/ModalAttachment.vue'
import { validateHeader, atributeValues } from '@/utils/upload'
import { formatToCurrencyBr, formatToNumber } from '@/utils/currency'

export default {
  name: 'FormDraftInitial',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  emits: ['updateForms'],
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    UploadExcelFile,
    DownloadExcelFile,
    UploadAttachment,
    ModalAttachment,
  },
  data() {
    return {
      formatToCurrencyBr,
      formatToNumber,
      showModalUploadExcel: false,
      showDownloadExcelFile: false,
      showModalUploadAttachment: false,
      showModalAttachments: false,
      isLoadingDraftInitial: true,
      isChecked: false,
      disableButton: false,
      fieldsMap: {
        volume: 'Volume',
        receitaBruta: 'Receita Bruta',
        deducoes: 'Deduções',
        receitaLiquida: 'Receita Líquida',
        custosVariaveis: 'Custos Variáveis',
        margemContribuicao: 'Margem de Contribuição',
        custosFixos: 'Custos Fixos',
        ebitda: 'Ebitda',
      },
      draftPreviousYear: {
        volume: 0,
        receitaBruta: 0,
        deducoes: 0,
        receitaLiquida: 0,
        custosVariaveis: 0,
        margemContribuicao: 0,
        custosFixos: 0,
        ebitda: 0,
      },
      draftVariation: {
        volume: '',
        receitaBruta: '',
        deducoes: '',
        receitaLiquida: '',
        custosVariaveis: '',
        margemContribuicao: '',
        custosFixos: '',
        ebitda: '',
      },
      draftBudget: {
        volume: '',
        receitaBruta: '',
        deducoes: '',
        receitaLiquida: '',
        custosVariaveis: '',
        margemContribuicao: '',
        custosFixos: '',
        ebitda: '',
      },
    }
  },
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },
  },
  methods: {
    importData(data) {
      const res = validateHeader(data, this.fieldsMap)
      if (!res.status) {
        this.$root.$refs.notification.error(res.message)
        return false
      }
      const values = atributeValues(data, this.fieldsMap)
      if (!values.status) {
        this.$root.$refs.notification.error(values.message)
        return false
      }
      // eslint-disable-next-line prefer-destructuring
      this.draftBudget = values.data[0]
      // percorre cada posição do objeto draftBudget chama a função calculateBudget
      Object.keys(this.draftBudget).forEach((key) => {
        this.calculateBudget(key, 'budget')
      })
      this.showModalUploadExcel = false
    },
    closeModal() {
      this.showModalUploadExcel = false
      this.showModalAttachments = false
      this.showModalUploadAttachment = false
    },
    calculateBudget(input, origin) {
      if (origin === 'variation') {
        const value = (
          this.draftPreviousYear[input] *
          (1 + this.draftVariation[input] / 100)
        ).toFixed(2)
        this.draftBudget[input] = Number(value)
      } else if (origin === 'budget') {
        const value = (
          ((this.draftBudget[input] - this.draftPreviousYear[input]) /
            this.draftPreviousYear[input]) *
          100
        ).toFixed(2)
        this.draftVariation[input] = Number(value)
      }
      // Receita Líquida
      this.draftBudget.receitaLiquida =
        this.draftBudget.receitaBruta - this.draftBudget.deducoes
      this.draftVariation.receitaLiquida = (
        Number(
          (this.draftBudget.receitaLiquida -
            this.draftPreviousYear.receitaLiquida) /
            this.draftPreviousYear.receitaLiquida,
        ) * 100
      ).toFixed(2)
      // Margem de Contribuição
      this.draftBudget.margemContribuicao = Number(
        this.draftBudget.receitaLiquida - this.draftBudget.custosVariaveis,
      ).toFixed(2)
      this.draftVariation.margemContribuicao = (
        Number(
          (this.draftBudget.margemContribuicao -
            this.draftPreviousYear.margemContribuicao) /
            this.draftPreviousYear.margemContribuicao,
        ) * 100
      ).toFixed(2)
      // Ebitda
      this.draftBudget.ebitda = Number(
        this.draftBudget.margemContribuicao - this.draftBudget.custosFixos,
      ).toFixed(2)
      this.draftVariation.ebitda = (
        Number(
          (this.draftBudget.ebitda - this.draftPreviousYear.ebitda) /
            this.draftPreviousYear.ebitda,
        ) * 100
      ).toFixed(2)
    },
    downloadTemplateDraftXls() {
      window.open(this.linkTemplateDraftXls, '_blank')
    },
    async getDraftInitial() {
      try {
        const draft = await this.$store.dispatch('getDraft', {
          companyCode: this.companyCode,
          formId: this.form.IDFORMULARIO,
          stepId: this.stepId,
        })
        this.draftPreviousYear = draft.draftPreviousYear
        if (draft.draftCurrentYear) {
          this.draftBudget = draft.draftCurrentYear
          Object.keys(this.draftBudget).forEach((key) => {
            this.calculateBudget(key, 'budget')
          })
        }

        this.isLoadingDraftInitial = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async saveDraft() {
      if (!this.v$.$invalid) {
        try {
          this.disableButton = true
          this.draft = await this.$store.dispatch('saveDraft', {
            form: {
              stepId: this.stepId,
              formId: this.form.IDFORMULARIO,
              draftBudget: this.draftBudget,
              draftVariation: this.draftVariation,
            },
            companyCode: this.companyCode,
          })
          this.$root.$refs.notification.success(this.draft.message)
          this.$emit('updateForms')
          this.isLoadingDraftInitial = false
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },
  validations() {
    return {
      isChecked: {
        sameAs: sameAs(true),
      },
      draftBudget: {
        volume: {
          required,
          minValue: minValue(1),
        },
        receitaBruta: {
          required,
          minValue: minValue(1),
        },
        deducoes: {
          required,
          minValue: minValue(1),
        },
        receitaLiquida: {
          required,
          minValue: minValue(1),
        },
        custosVariaveis: {
          required,
          minValue: minValue(1),
        },
        margemContribuicao: {
          required,
          minValue: minValue(1),
        },
        custosFixos: {
          required,
          minValue: minValue(1),
        },
        ebitda: {
          required,
          minValue: minValue(1),
        },
      },
    }
  },
  mounted() {
    this.getDraftInitial()
  },
}
</script>
